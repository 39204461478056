import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddDippingForm = ({ index, formData, changeFormValue }) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Dipped Count</label>
                <input
                  required
                  type="number"
                  value={formData?.dippedCount}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "dippedCount");
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Enter Dipped Count"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Medicine Applied</label>
                <input
                  required
                  type="text"
                  value={formData?.medicineApplied}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "medicineApplied");
                  }}
                  placeholder="Enter Medicine Applied" 
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Spray Count</label>
                <input
                  required
                  type="number"
                  value={formData?.sprayCount}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "sprayCount");
                  }}
                  placeholder="Enter Spray Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Vaccinated Count</label>
                <input
                  required
                  type="number"
                  value={formData?.vaccinatedCount}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "vaccinatedCount");
                  }}
                  placeholder="Enter Vaccinated Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Vaccination Count</label>
                <input
                  required
                  type="number"
                  value={formData?.vaccinationCount}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "vaccinationCount");
                  }}
                  placeholder="Enter Vaccination Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDippingForm;
