import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddLivestockHealthMedicationForm = ({
  index,
  formData,
  changeFormValue,
}) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Disease Type</label>
                <input
                  required
                  type="text"
                  value={formData?.diseaseType}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "diseaseType");
                  }}
                  placeholder="Enter Disease Type"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Affected</label>
                <input
                  required
                  type="number"
                  value={formData?.animalAffected}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalAffected");
                  }}
                  placeholder="Enter Animal Affected"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Treated</label>
                <input
                  required
                  type="number"
                  value={formData?.animalTreated}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalTreated");
                  }}
                  placeholder="Enter Animal Treated"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Recovered</label>
                <input
                  required
                  type="number"
                  value={formData?.animalRecovered}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalRecovered");
                  }}
                  placeholder="Enter Animal Recovered"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Died</label>
                <input
                  required
                  type="number"
                  value={formData?.animalDied}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalDied");
                  }}
                  placeholder="Enter Animal Died"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Medicine Applied</label>
                <input
                  required
                  type="text"
                  value={formData?.medicineApplied}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "medicineApplied");
                  }}
                  placeholder="Enter Medicine Applied" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLivestockHealthMedicationForm;
