import React, { useEffect, useRef, useState } from "react";
import cardboxbg from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import {
  fetchRegions,
  fetchUserList,
  updateUserStatus,
} from "../../services/user";
import { useNavigate } from "react-router-dom";

import UserListItem from "../../components/user/userListItem";
import { Button, Modal } from "react-bootstrap";
import Notfound from "../../components/data-not-found";
import { useSelector } from "react-redux";

const UserList = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [zoneList, setZoneList] = useState([]);
  const [search, setSearch] = useState("");
  const [regionId, setRegionId] = useState([]);
  const [arrow, setArrow] = useState(false);
  const [zoneDrop, setZoneDrop] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [currentZone, setCorrentZone] = useState([]);
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const getRegions = async () => {
    const res = await fetchRegions();
    if (res.status) {
      setZoneList(res.data);
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  const getUsersList = async () => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    console.log(regionId);
    const payload = {
      regionId: regionId.length ? regionId : "",
      search: search,
      pageNo: page,
      pageSize: pageSize,
    };
    const res = await fetchUserList(payload, startDate, endDate);
    if (res.status) {
      setUserList(res.data.officersList);
      setTotalData(res.data.countRecords);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };

  const goBack = () => {
    navigate(-1);
  };
  const openZoneDropdown = () => {
    setZoneDrop(!zoneDrop);
    setArrow(!arrow);
  };
  const ref2 = useRef();
  console.log("cZne  ", currentZone);
 
  const handleClickOutside = (event) => {
    if (ref2.current && !ref2.current.contains(event.target)) {
      setZoneDrop(false);
      setArrow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const changeUserActive = async () => {
    const payload = {
      status: !selectedUser.status,
    };
    const res = await updateUserStatus(selectedUser._id, payload);
    if (res.status) {
      getUsersList();
      setShow(false);
      setOpacity(0);
    }
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>User Management</h3>
          <button onClick={goBack}>
            {" "}
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardboxbg}
            alt="card bg"
          />
        </div>
      </div>{" "}
      <div className="politryStockForm ">
        <div className="politryStock addForm usrlsttbl">
          <div className="row">
            <div className="col-md-3">
              <div>
                <div className="addFormRow">
                  <label> Zone</label>

                  {/* <select
                      name=""
                      id=""
                      value={regionId}
                      onChange={(e) => {
                        setRegionId(e.target.value);
                      }}>
                      <option value="">All Zone</option>
                      {zoneList &&
                        zoneList?.map((value) => {
                          return (
                            <option value={value._id}>{value.name}</option>
                          );
                        })}
                    </select> */}
                  <div
                    className="checkBoxPopupbtn"
                    onChange={(e) => {
                      setRegionId(e.target.value);
                    }}
                    onClick={openZoneDropdown}
                    ref={ref2}>
                    {regionId.length
                          ? `${currentZone[0]}${currentZone?.length > 1 ? `, + ${currentZone.length - 1} zone${currentZone.length > 2 ? 's' : ''}` : ""}`
                          : "Select Zone"}
                    {arrow ? (
                      <i class="arrow up "></i>
                    ) : (
                      <i class="arrow down"></i>
                    )}
                  </div>
                </div>
                <div
                ref={ref2}
                  className={
                    zoneDrop
                      ? "dropdown-list multiselect"
                      : "dropdown-list multiselect datehide"
                  }>
                  {zoneList &&
                    zoneList?.map((value, index) => {
                      return (
                        <label
                          class="dropdown-option"
                          key={index}>
                          <input
                            type="checkbox"
                            name="dropdown-group"
                            checked={regionId.some((data) => data == value._id)}
                            id={index}
                            onChange={(e) => {
                              const checkExist = regionId.some(
                                (data) => data == value._id
                              );
                              if (checkExist) {
                                setRegionId(
                                  regionId.filter((regionId) => {
                                    return regionId !== value._id;
                                  })
                                );
                                setCorrentZone(
                                  currentZone.filter((region) => {
                                    return region !== value.name;
                                  })
                                );
                              } else {
                                setRegionId([...regionId, value._id]);
                                setCorrentZone([...currentZone, value.name]);
                              }
                            }}
                          />
                          {value.name}
                        </label>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="addFormRow">
                <label> Search</label>
                <input
                  type="text"
                  placeholder="Search by user id or user name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      getUsersList();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="bottomButtons userMngmntBtn">
                <button
                  type="button"
                  className="addprdt mngmntApplybtn active"
                  id="addprdt"
                  onClick={() => {
                    getUsersList();
                  }}
                  // onChange={(e) => {
                  //   setRegionId(e.target.value);
                  // }}
                >
                  APPLY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="usermngntPageTable tableBlockPage">
        <table className="table table-bordered usermngmntTable userListTbl ">
          <thead>
            <tr>
              <th className="userListtbl">USER ID</th>
              <th className="userListtbl">USER NAME</th>
              <th className="userListtbl">PASSWORD</th>
              <th className="userListtbl">ROLE</th>
              <th className="userListtbl">ACCESS</th>
              <th className="userListtbl">ZONE</th>
              <th className="userListtbl">STATUS</th>
              <th className="userListtbl">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {userList?.length > 0 ? (
              <>
                {userList?.map((value) => {
                  return (
                    <UserListItem
                      value={value}
                      onChange={() => {
                        getUsersList();
                      }}
                      onStatusChange={(value) => {
                        handleShow();
                        setSelectedUser(value);
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <div className="tablenotfound">
                <Notfound />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Status?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to change the status?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              changeUserActive();
            }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;
