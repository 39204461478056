import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchCropDiseaseList } from "../../../services/plantHealthControl";
import { useNavigate } from "react-router-dom";
import cardboxbg from "../../../assets/images/cardboxbg.png";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import Notfound from "../../data-not-found";
import ClickTooltip from "./locatoinTooltip";
import { getLocationInfo } from "../../../helpers/helpers";

const CropDiseaseTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [cropDiseaseList, setCropDiseaseList] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [locationInfo, setLocationInfo] = useState("");

  const getDiseaseList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchCropDiseaseList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setCropDiseaseList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      setTotalData(res.count);
    }
  };

  useEffect(() => {
    getDiseaseList(page);
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return loading ? (
    <Shimmer />
  ) : (
    <>
      <div className="homepgtable">
        <table className="table-bordered home-table">
          <thead>
            <tr>
              {cropDiseaseList &&
                cropDiseaseList[0]?.header?.map((value, index) => {
                  return (
                    <th
                      rowSpan="2"
                      className={index < 4 ? "meatinspheadLeft" : ""}
                    >
                      {" "}
                      <strong>{value}</strong>
                    </th>
                  );
                })}
              <th rowSpan="2">
                {" "}
                <strong>Updated Location</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {cropDiseaseList && cropDiseaseList[0]?.row?.length > 0 ? (
              <>
                {cropDiseaseList &&
                  cropDiseaseList[0]?.row?.map((value) => {
                    return (
                      <tr>
                        <td className="employeeName">{value.userName}</td>
                        <td className="dates">
                          {moment(value.date).format("DD-MMM-yyyy")}
                        </td>
                        <td className="animaltype">
                          {value.cropName === " " || value.cropName === ""
                            ? "-"
                            : value.cropName}
                        </td>
                        <td className="animaltype">
                          {value.diseaseName === " " || value.diseaseName === ""
                            ? "-"
                            : value.diseaseName}
                        </td>
                        <td className="alignmd">{value.villagesAffected}</td>
                        <td className="alignmd">
                          {value.severity === " " ? "-" : value.severity}
                        </td>
                        <td>
                          <div className="incomeNotes recommended">
                            {value.agroRecommended === "" ||
                            value.agroRecommended === " "
                              ? "-"
                              : value.agroRecommended}
                          </div>
                        </td>
                        <td>
                          <div className="incomeNotes">
                            {value.comment === "" || value.comment === " "
                              ? "-"
                              : value.comment}
                          </div>
                        </td>
                        <td className="alignmd">
                          <ClickTooltip tooltipText={locationInfo}>
                            <button
                              className="btn btn-primary"
                              onClick={async () => {
                                let location = await getLocationInfo(
                                  value?.extras?.lat,
                                  value?.extras?.long
                                );
                                setLocationInfo(location);
                              }}
                            >
                              View Location
                            </button>
                          </ClickTooltip>
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <div className="tablenotfound">
                <Notfound />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}{" "}
    </>
  );
};

export default CropDiseaseTable;
