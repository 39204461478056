import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ClickTooltip = ({ children, tooltipText }) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);

  // Handle outside click to hide tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={targetRef} onClick={() => setShow(!show)}>
      <OverlayTrigger
        show={show}
        placement="left"
        overlay={<Tooltip>{tooltipText}</Tooltip>}
        target={targetRef.current}
      >
        {children}
      </OverlayTrigger>
    </div>
  );
};

export default ClickTooltip;
