import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import editbtn from "../../assets/images/mngmntEdit.png";
import PassVis from "../../assets/images/tablepasviss.png";
import eyeslash from "../../assets/images/eyeslash.svg";
import { updateUserStatus } from "../../services/user";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const UserListItem = ({ value, onChange, onStatusChange }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const permissionType = {
    READ: "Read",
    WRITE: "Write",
    FULLACCESS: "Full Access",
  };

  const status = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  };

  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [changeStatus, setChangeStatus] = useState();
  const handleClose = () => {
    setChangeStatus(0);
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const handleChange = () => {
    setChangeStatus(1);
    handleClose();
  };

  const changeUserActive = async (id, payload) => {
    const res = await updateUserStatus(id, payload);
    if (res.status) {
      onChange();
    }
  };

  return (
    <tr>
      <td className="userListEmail">{value.email}</td>
      <td className="userListEmail">{value.name}</td>
      <td className="tablePassword">
        <div
          className="mngTblPass mngpass"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? value.passwordString : "********************"}
          <img
            className="tablepassIcon"
            src={showPassword ? PassVis : eyeslash}
            alt=""
          />
        </div>
      </td>
      <td>{value.roleDetails.name}</td>
      <td>
        {value.permissionType == 1
          ? permissionType.READ
          : value.permissionType == 2
          ? permissionType.WRITE
          : permissionType.FULLACCESS}
      </td>
      <td>{value.regionDetails?.map(el=>el.name).join(", ")}</td>
      <td>
        <div className="tableSwitchbtn">
          <label class="switch">
            <input
              type="checkbox"
              checked={value.status}
              onChange={() => {
                onStatusChange(value);
              }}
            />
            <span class="slider round"></span>
          </label>
          {value.status == true ? status.ACTIVE : status.INACTIVE}
        </div>
      </td>
      <td align="center">
        <img
          className="bg-transparent c-pointer edtbtn"
          src={editbtn}
          alt=""
          onClick={() => {
            navigate(`/edit-user/${value._id}`);
          }}
        />
      </td>
    </tr>
  );
};

export default UserListItem;
