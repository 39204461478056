import { Box, Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { eggLogPage } from "../../../services/addEgg";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import { getLocationInfo, numberFormat } from "../../../helpers/helpers";
import Notfound from "../../data-not-found";
import Papa from "papaparse";
import ClickTooltip from "./locatoinTooltip";

const EggTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [colorCount, setColorCount] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [locationInfo, setLocationInfo] = useState("");

  const getEggRecords = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await eggLogPage(page, pageSize, regionId, startDate, endDate);
    if (res.status) {
      setLoading(false);
      setRecord(res.data);
      setColorCount(res.data.colorCount + 1);
      setTotalData(res.data.count);
    }
  };

  useEffect(() => {
    getEggRecords(page);
  }, [zoneRefresh, pageSize, page]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };

  return loading ? (
    <Shimmer />
  ) : (
    <>
      <div className="homepgtable">
        <table className="table-bordered home-table ">
          <thead>
            <tr>
              {record &&
                record?.header?.map((value, index) => {
                  if (
                    value == "Small" ||
                    value == "Medium" ||
                    value == "Large" ||
                    value == "XtraLarge"
                  ) {
                    return (
                      <th colSpan="3" class=" thd xlLarge">
                        <strong>{value + " Eggs"}</strong>
                      </th>
                    );
                  } else {
                    if (index < 3) {
                      return (
                        <th rowSpan="2" class="thd alignmdleft">
                          <strong>{value}</strong>
                        </th>
                      );
                    } else if (index > 13) {
                      return (
                        <th rowSpan="2" class="thd alignmd">
                          <strong>{value}</strong>
                        </th>
                      );
                    }
                  }
                })}
              <th rowSpan="2">
                <strong>Updated Location</strong>
              </th>
            </tr>
            <tr class="homepagetbl">
              {record &&
                record?.header
                  ?.slice(3, colorCount * 4 + 3)
                  ?.map((value, index) => {
                    return <th className="eggMiddle">{value}</th>;
                  })}
            </tr>
          </thead>
          <tbody>
            {record?.eggLogDetails?.length > 0 ? (
              <>
                {record &&
                  record?.eggLogDetails?.map((value) => {
                    return (
                      <tr>
                        <td className="employeeName">{value.userName}</td>
                        <td className="dates">
                          {moment(value.date).format("DD-MMM-yyyy")}
                        </td>
                        <td className="EggType">{value.animalName}</td>
                        {value.sizeArray.map((data, index) => {
                          return (
                            <td className={index < 12 ? " " : "eggTotal"}>
                              {data.count > 0 ? numberFormat(data.count) : "-"}
                            </td>
                          );
                        })}
                        <td className="alignmd">
                          <ClickTooltip tooltipText={locationInfo}>
                            <button
                              className="btn btn-primary"
                              onClick={async () => {
                                let location = await getLocationInfo(
                                  value?.extras?.lat,
                                  value?.extras?.long
                                );
                                setLocationInfo(location);
                              }}
                            >
                              View Location
                            </button>
                          </ClickTooltip>
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <div className="tablenotfound">
                <Notfound />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}{" "}
    </>
  );
};

export default EggTable;
